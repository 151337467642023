<template>
  <v-container fluid class="pl-16">
    <v-row no-gutters>
      <!-- Chat Window -->
      <v-col cols="12" class="chat-window">
        <v-card class="chat-wrapper">
          <v-toolbar flat class="chat-header pl-2">
            <v-avatar style="overflow:visible">
              <UserImage :user="{ id: adminUserId }" xsmall />
            </v-avatar>
            <v-toolbar-title class="pl-2">
              {{ adminName }}
              <span :class="getStatusClass(adminStatus)">
                {{ getStatusText(adminStatus) }}
              </span>
            </v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <!-- Scrollable Chat Messages -->
          <v-card-text class="chat-content">
            <div v-if="messages.length > 0" class="chat-messages">
             <div
  v-for="(message, index) in messages"
  :key="index"
  :class="{ 'from-user': message.fromUser, 'from-admin': !message.fromUser }"
  class="message-container"
>
  <div v-if="message.type === 'text'">
    <v-chip
      dark
      class="mb-2"
      :color="message.fromUser ? '#128c7e' : ''"
      :class="{ 'from-user-chip': message.fromUser }"
    >
      {{ message.text }}
    </v-chip>
  </div>
  <div v-else-if="message.type === 'image'">
    <v-img
      :src="message.fileUrl"
      max-width="200"
      max-height="200"
      class="mb-2"
    ></v-img>
  </div>
  <div v-else-if="message.type === 'video'">
    <video
      :src="message.fileUrl"
      controls
      width="200"
      class="mb-2"
    ></video>
  </div>
  <div v-else-if="message.type === 'file'">
    <v-chip
      class="mb-2"
      :color="message.fromUser ? '#128c7e' : ''"
      :class="{ 'from-user-chip': message.fromUser }"
      @click="downloadFile(message.fileUrl)"
    >
      <v-icon left>mdi-file</v-icon>
      {{ message.fileName }}
    </v-chip>
  </div>
</div>
</div>


            <!-- Typing Indicator -->
            <div v-if="isAdminTyping" class="typing-indicator">
              <v-chip class="mb-2">{{ adminName }} está escribiendo...</v-chip>
            </div>
            <v-alert v-else-if="messages.length === 0" type="info" text>
              No hay mensajes para mostrar. ¡Empieza la conversación!
            </v-alert>
          </v-card-text>
          <v-divider />
          <!-- Message Input Section -->
          <v-card-actions class="message-input">
            <v-progress-linear
              v-if="uploading"
              :value="uploadProgress"
              height="2"
              color="primary"
            ></v-progress-linear>
            <v-text-field
              v-model="newMessage"
              label="Type a message"
              outlined
              dense
              hide-details
              class="rounded-lg"
              @keyup.enter="sendMessage"
              @input="handleTyping"
              :disabled="uploading"
            >
              <template v-slot:append>
                <v-icon @click="triggerFileInput">mdi-paperclip</v-icon>
                <v-icon @click="sendMessage" :color="newMessage ? 'primary' : ''"
                  >mdi-send</v-icon
                >
              </template>
            </v-text-field>
            <!-- Hidden File Input -->
            <input
              type="file"
              ref="fileInput"
              @change="handleFileUpload"
              style="display: none"
            />
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  getDatabase,
  ref as dbRef,
  set,
  update,
  push,
  onValue,
  onDisconnect,
  get,
} from 'firebase/database';
import {
  getStorage,
  ref as storageRef,
  uploadBytesResumable,
  getDownloadURL,
} from 'firebase/storage';
import UserImage from '@/components/profile/UserImage.vue';

export default {
  components: {
    UserImage,
  },
  data() {
    return {
      messages: [],
      newMessage: '',
      userId: null,
      conversationId: null,
      db: null,
      adminStatus: 'offline', // Admin's online status
      adminUserId: '4741184', // Replace with the actual admin user ID
      adminName: 'Admin', // Admin's name
      isAdminTyping: false, // Admin typing status
      typingTimeout: null, // Timeout for typing indicator
      uploading: false, // Uploading state
      uploadProgress: 0, // Upload progress percentage
    };
  },
  methods: {
    async loadConversation() {
      const userId = this.$store.state.Auth.token.claims.user_id;
      this.userId = userId;

      // Save conversation ID
      this.conversationId = userId;
      // Get Realtime Database reference
      this.db = getDatabase();

      // Listen for messages in the conversation
      const messagesRef = dbRef(this.db, `interactions/${userId}/messages`);
      onValue(messagesRef, (snapshot) => {
        const data = snapshot.val();
        this.messages = [];
        if (data) {
          this.messages = Object.keys(data)
            .map((key) => ({ ...data[key], id: key }))
            .sort((a, b) => a.timestamp - b.timestamp);
        }

        // Scroll to the bottom of the chat window
        this.$nextTick(() => {
          const chatContent = this.$el.querySelector('.chat-content');
          if (chatContent) {
            chatContent.scrollTop = chatContent.scrollHeight;
          }
        });
      });

      // Listen for admin status and name
      this.listenForAdminStatus();

      // Listen for admin typing status
      this.listenForAdminTyping();
    },
    async sendMessage() {
      if (this.newMessage.trim() !== '' && this.userId) {
        const message = {
          text: this.newMessage,
          fromUser: true,
          timestamp: Date.now(),
          type: 'text',
        };

        await this.sendMessageToDatabase(message);

        // Reset typing status
        this.setTypingStatus(false);

        this.newMessage = '';
      }
    },
    async sendMessageToDatabase(message) {
      const conversationRef = dbRef(this.db, `interactions/${this.userId}`);
      const messagesRef = dbRef(this.db, `interactions/${this.userId}/messages`);
      const newMessageRef = push(messagesRef);

      // Check if interaction exists
      const interactionSnapshot = await get(conversationRef);
      if (!interactionSnapshot.exists()) {
        // Create a new interaction
        await set(conversationRef, {
          type: 'in-app-chat',
          lastMessage: message.type === 'text' ? message.text : 'Sent a file',
          lastMessageDate: Date.now(),
          unreadCount: 1,
        });
      } else {
        // Update existing interaction
        const currentUnreadCount = interactionSnapshot.val().unreadCount || 0;
        await update(conversationRef, {
          lastMessage: message.type === 'text' ? message.text : 'Sent a file',
          lastMessageDate: Date.now(),
          unreadCount: currentUnreadCount + 1,
        });
      }

      // Add message to Realtime Database
      await set(newMessageRef, message);
    },
    setUserOnline(userId) {
      const db = getDatabase();
      const userStatusRef = dbRef(db, `/status/${userId}`);

      set(userStatusRef, {
        name: this.$store.state.Auth.token.claims.name,
        state: 'online',
        lastChanged: Date.now(),
      });
      onDisconnect(userStatusRef).set({
        name: this.$store.state.Auth.token.claims.name,
        state: 'offline',
        lastChanged: Date.now(),
      });
    },
    listenForAdminStatus() {
      const db = getDatabase();
      const adminStatusRef = dbRef(db, `/status/${this.adminUserId}`);

      onValue(adminStatusRef, (snapshot) => {
        const status = snapshot.val();
        if (status) {
          this.adminStatus = status.state || 'offline';
          this.adminName = status.name || 'Admin';
        } else {
          this.adminStatus = 'offline';
          this.adminName = 'Admin';
        }
      });
    },
    listenForAdminTyping() {
      const db = getDatabase();
      const typingRef = dbRef(db, `/typing/${this.conversationId}/admin`);

      onValue(typingRef, (snapshot) => {
        this.isAdminTyping = snapshot.val() || false;
      });
    },
    handleTyping() {
      // Set typing status to true
      this.setTypingStatus(true);

      // Clear existing timeout
      if (this.typingTimeout) {
        clearTimeout(this.typingTimeout);
      }

      // Set typing status to false after a delay
      this.typingTimeout = setTimeout(() => {
        this.setTypingStatus(false);
      }, 2000); // Adjust delay as needed
    },
    setTypingStatus(isTyping) {
      const db = getDatabase();
      const typingRef = dbRef(db, `/typing/${this.conversationId}/user`);

      set(typingRef, isTyping);
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (!file) return;

      this.uploading = true;
      const storage = getStorage();
      const storageReference = storageRef(
        storage,
        `interactions/${this.conversationId}/${Date.now()}_${file.name}`
      );

      const uploadTask = uploadBytesResumable(storageReference, file);

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          this.uploadProgress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.error('File upload error:', error);
          this.uploading = false;
          this.uploadProgress = 0;
        },
        async () => {
          this.uploading = false;
          this.uploadProgress = 0;
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

          let messageType = 'file';
          if (file.type.startsWith('image/')) {
            messageType = 'image';
          } else if (file.type.startsWith('video/')) {
            messageType = 'video';
          }

          const message = {
            fromUser: true,
            timestamp: Date.now(),
            type: messageType,
            fileUrl: downloadURL,
            fileName: file.name,
            fileType: file.type,
          };

          await this.sendMessageToDatabase(message);
        }
      );
    },
    downloadFile(url) {
      window.open(url, '_blank');
    },
    getStatusText(status) {
      return status === 'online' ? 'Online' : 'Offline';
    },
    getStatusClass(status) {
      return status === 'online' ? 'text-success' : 'text-secondary';
    },
  },
  async mounted() {
    await this.loadConversation();
    this.setUserOnline(this.$store.state.Auth.token.claims.user_id);
  },
  beforeDestroy() {
    // Clean up typing status when component is destroyed
    this.setTypingStatus(false);
    if (this.typingTimeout) {
      clearTimeout(this.typingTimeout);
    }
  },
};
</script>

<style scoped>

.message-container {
  display: flex;
  justify-content: flex-start;
}

.from-user {
  justify-content: flex-end; /* Align user messages to the right */
}

.from-user-chip {
  align-self: flex-end; /* Align user chips to the right */
}

.from-admin {
  justify-content: flex-start; /* Align admin messages to the left */
}

.chat-wrapper {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 70px);
}

.chat-header {
  flex-shrink: 0;
  display: flex;
  align-items: center;
}

.chat-header .v-toolbar-title {
  display: flex;
  align-items: center;
}

.chat-content {
  height: 100%;
  overflow-y: auto;
  margin-bottom: 16px;
}

.chat-messages {
  margin-bottom: 16px;
}

.typing-indicator {
  margin-left: 16px;
}

.message-input {
  flex-shrink: 0;
}

/* Styles for messages */
.from-user {
  text-align: right;
}

.from-admin {
  text-align: left;
}

.from-user-chip {
  background-color: #128c7e;
  color: white;
}

.text-success {
  color: green;
}

.text-secondary {
  color: grey;
}
</style>
